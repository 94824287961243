import React, { useEffect, useState, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { isIOS, isAndroid } from "react-device-detect";

import axios from "axios";
import "./../../styles/default.css";

import { leafletState } from "./constants";

import fixedLeaflet from "./data/fixedLeaflet";
import thisWeek from "./data/202206_3";
import willThisWeek from "./data/202206_4";

function List() {
  const location = useLocation();
  const navigate = useNavigate();
  const leafletNum = location.pathname.split("lm/")[1];

  const [leafletInfo, setLeafletInfo] = useState();
  const [imageInfoList, setImageInfoList] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [slideIdx, setSlideIdx] = useState(0);

  //마트몰 이동 스키마
  const goMartMall = (href) => {
    window.gtag("event", `전단 클릭(${href})`, {
      event_category: `[전단지] ${leafletInfo.title}`,
      event_label: "",
    });

    let url =
      "https://www.lotteon.com/p/display/shop/seltDpShop/12919?mall_no=4";

    var isMcouponApp = navigator.userAgent.indexOf("_mcoupon") > -1;

    if (isMcouponApp) {
      if (isAndroid) {
        //안드로이드
        // window.base?.popup_open(url);
        window.base?.openCulture(url);
      } else if (isIOS) {
        //IOS
        // window.webkit.messageHandlers.popup_open.postMessage({
        //   url: url.replace(/&/g, "|@|"),
        // });
        window.webkit.messageHandlers.openCulture.postMessage({
          url: url.replace(/&/g, "|@|"),
        });
      }
    } else {
      //비 모바일 처리
      window.location.href = url;
    }
  };

  //전단 데이터 추출
  const returnLeafletInfo = useCallback(
    (isThisWeek) => {
      const leafletComp = isThisWeek ? thisWeek : willThisWeek;
      const sumLeaflet = { ...leafletComp, ...fixedLeaflet }; //고정전단 합치기

      const data = sumLeaflet[`L${leafletNum}`];

      if (data) {
        setLeafletInfo({
          imgPath: data.imgPath,
          title: data.title,
          type: data.type,
        });
        setImageInfoList(data.contents);
      }
    },
    [leafletNum]
  );

  //전단 노출 시간 체크
  const getCrntTimeCheck = useCallback(() => {
    // 새로운 전단지 작업시 reload 되는 걸 막기 위해
    const _leafletStateThisWeek = leafletState.thisWeek.concat(
      leafletState.willThisWeek
    );

    axios
      .get("https://www.mcouponapp.com/apis/hybridapp/renew/common/crnttime")
      .then((res) => {
        const crntTime = res.data.data.crnt_time.substr(0, 8);

        const leafletIndex =
          crntTime - leafletState.openDate >= 0
            ? leafletState.willThisWeek
            : _leafletStateThisWeek;

        // 현재 진행중인 행사가 아닌 경우 redirect
        if (leafletIndex.indexOf(leafletNum) < 0) {
          // `http://mlotte.kr/leafelt?num=${leafletIndex[0]}`
          if ("699" < leafletNum && leafletNum < "800") {
            //빅마켓
            navigate(`/lm/${leafletIndex[2]}`);
          } else {
            //전점
            navigate(`/lm/${leafletIndex[0]}`);
          }
        } else {
          // 현재 진행중인 전단
          const isThisWeek =
            crntTime - leafletState.openDate >= 0 ? false : true;
          returnLeafletInfo(isThisWeek);
        }
      })
      .then(() => {
        setIsSuccess(true);
      });
  }, [leafletNum, navigate, returnLeafletInfo]);

  const changeSlide = (type) => {
    if (type === "next") {
      const nextIdx = slideIdx + 1;

      if (nextIdx < imageInfoList.length) {
        setSlideIdx(nextIdx);
      } else {
        //마지막 컨텐츠 넘어갈 경우 첫번째로 이동
        setSlideIdx(0);
      }
    } else if (type === "prev") {
      const prevIdx = slideIdx - 1;

      if (prevIdx < 0) {
        //첫번째 컨텐츠 넘어갈 경우 마지막으로 이동
        setSlideIdx(imageInfoList.length - 1);
      } else {
        setSlideIdx(slideIdx - 1);
      }
    }
  };

  useEffect(() => {
    getCrntTimeCheck();
  }, [getCrntTimeCheck]);

  useEffect(() => {
    if (!isSuccess) return;
    if (!leafletInfo) return;

    window.gtag("config", "GA_TRACKING_ID", {
      page_title: `[전단지] ${leafletInfo.title}`,
      page_location: location.pathname,
      page_path: `[전단지] ${leafletInfo.title}`,
    });

    window.gtag("event", "screen_view", {
      screen_name: "leaflet",
      app_name: "leaflet",
      app_id: "leaflet",
      title: `[전단지] ${leafletInfo.title}`,
      page: `[전단지] ${leafletInfo.title}`,
    });
  }, [isSuccess, leafletInfo, location.pathname]);

  const imgInfo = imageInfoList[slideIdx];

  return (
    <>
      {imageInfoList.length > 0 && (
        <>
          <div className="wrapper subCont">
            <div className="headerCont2">
              <h1 className="logoTit">{imgInfo.title}</h1>
            </div>
            <a href={imgInfo.href}>
              <img
                src={`${leafletInfo.imgPath}/${imgInfo.imgs}`}
                alt={imgInfo.title}
              />
              {/* 장애인차별금지법 웹표준 퍼블리싱 figcaption */}
              <div
                className="alternate"
                dangerouslySetInnerHTML={{ __html: imgInfo.texts }}
              />
            </a>
          </div>
          {leafletInfo.type && (
            <>
              <div className="footer">
                <p>
                  <img
                    src="https://imagecoupon.lottemart.com/lmscp/leaflet/resource/image/common/txt_product_info.png"
                    alt="더 많은 상품이 매장에 준비되어 있습니다"
                  />
                </p>

                {leafletInfo.type === "HYPER" ? (
                  <button onClick={() => goMartMall(imgInfo.href)}>
                    <img
                      src="https://imagecoupon.lottemart.com/lmscp/leaflet/resource/image/common/btn_malllink.png"
                      alt="롯데마트몰 바로가기"
                    />
                  </button>
                ) : (
                  leafletInfo.type === "MAXX" && (
                    <p>
                      <img
                        src="https://imagecoupon.lottemart.com/lmscp/leaflet/resource/image/common/btn_maxx.png"
                        alt="롯데마트maxx"
                      />
                    </p>
                  )
                )}
              </div>
            </>
          )}
        </>
      )}
      {/* 버튼 */}
      <div
        className="swiper-button-next"
        onClick={() => changeSlide("next")}
      ></div>
      <div
        className="swiper-button-prev"
        onClick={() => changeSlide("prev")}
      ></div>
    </>
  );
}

export default List;
