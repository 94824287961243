export const LEAFLET_URL = {
  a1: "http://www.lottemart.com",
  a2: "http://toysrus.lottemart.com",
  chu2020: "https://www.lotteon.com/p/display/shop/seltDpShop/29638",
};

/* 지난 전단지 reload */
export const leafletState = {
  // openDate는 오픈날짜로 변경(매주 목요일)
  openDate: "20220621",
  thisWeek: ["401", "402", "403", "404"],
  willThisWeek: ["401", "402", "403", "404"],
};
