import { LEAFLET_URL } from "../constants";

const data = {
  L401: {
    title: "스마트전단지-전점401", //GA수집용 제목
    type: "HYPER", //푸터 이미지 구분
    imgPath: "static/202205_4/",
    contents: [
      {
        href: LEAFLET_URL.a1,
        imgs: "01/01.jpg",
        title: "롯데마트 전단1면",
        texts: `
                      <h1>·전단적용기간 : 2022. 5. 26(목) ~ 2022. 5. 31(화)</h1>
                      <div>
                          기다렸던 일상의 재발견 3탄
                          맛있는 한끼 밥상
  
                          노을 메론(통/국산) 9,990원 설향 메론(1.5kg/박스/국산) 11,900원 브랜드 돼지 삼겹/목심 숯불용 각3,480원 초이스엘 국내산 대패 삼겹/목심 20,640/16,640원 물가안정 제주갈치 대/특대 4,900/9,100원
                      </div>
                      `,
      },
      {
        href: LEAFLET_URL.a1,
        imgs: "01/02.jpg",
        title: "롯데마트 전단2면",
        texts: `
                      <div>
                          우리가족 건강한 한 끼 밥상
  
                          부추 1,890원 현미/찰현미/혼합 8곡 각9,490원 상생 대파 2,490원 물가안정 참조기 1,800원 물가안정 국물용 멸치 8,900원 정부비축 울릉도 마른오징어 16,900원 통영 멍게 6,980원 한우 세절 국거리 기획팩 20,900원
  
                          농림축산식품부와 함께하는 농할할인 20%
                          갓 따온 그대로 사과 7,992원 대추방울 토마토 6,960원 다다기 오이 2,392원 양배추 2,952원 햇마늘 14,320원 파프리카 792원 양파 3,992원 산지직송 영암감자 5,592원 더담은 임금님표 이천쌀 30,320원 동물복지 닭볶음탕/백숙 각7,990원
                      </div>
                      `,
      },
      {
        href: LEAFLET_URL.a1,
        imgs: "01/03.jpg",
        title: "롯데마트 전단3면",
        texts: `
                      <div>
                          밀키트로 채우는 따뜻한 한 끼 밥상!
  
                          사천 마라샹궈 11,600원 요리하다×농가맛집 소고기버섯전골 16,720원 해물버섯 누룽지탕 12,720원 요리하다 우렁강된장 11,120원 요리하다 된장시래기 전골 12,720원 
  
                          청정원 호밍스 2만 5천원 이상 5천원 롯데상품권 증정!
                          호밍스 청송식 닭불고기, 언양식 바싹불고기/춘천식 닭갈비, 부산식 곱창전골/서울식 소불고기 전골, 낙곱새전골, 소고기버섯만두전골 각6,735/9,750/12,000원
  
                          오뚜기 케찹 外 10종 2,280~3,690원 르네 홀그레인 머스타드/마스터푸드 민트젤리 소스 3,890/4,990원 백설 천일염 허브맛솔트 마늘 外 3종 2,280~2,680원 신영 양꼬치 시즈닝 外 9종 1,790~14,990원
  
                          트라몬티나 커트러리 전품목 2,450~10,430원 룸바이홈 미드센츄리 식기/수저 4,560~16,900원 바이올라 와인글라스 14,000원 바인즈 단스탠드 外 2종 23,900~33,900원 룸바이홈 방수 스트라이프 식탁보 24,900원 이연복 클래딘 시그니처 프라이팬 29,520 ~35,920 원 룸바이홈 골드 프라이팬 시리즈 8,450~12,450 원
                      </div>
                      `,
      },
      {
        href: LEAFLET_URL.a1,
        imgs: "01/04.jpg",
        title: "롯데마트 전단4면",
        texts: `
                      <div>
                          먹.잘.알들의 PICK! 맛있는 캠핑 ITEM!
                          특별하게 즐기는 미국산 특수부위 4종! 스킨팩 토시살/꽃갈비살/본갈비살/립아이캡 L.POINT 5천원 할인 초이스 부채살 2,280원 초이스 꽃갈비살 7,560원 수제 모둠소시지 10,000원 불맛한판 직화 모듬곱창/불곱창 6,480/5,480원 간편하게 즐기는 냉동 스테이크 3종 L.POINT30%
  
                          초여름 더위를 꽉 잡는 몸보신용 한우 곰탕류 최대 L.POINT50%
                          한우 사골/잡뼈 8,250/5,500원 지리산 사골/고기 곰탕 L.POINT30%
  
                          스무가지 비타민과 미네랄! 달콤하게 시작하는 즐거운 건강습관!
                          제스프리 그린/골드 키위 6,990/9,990원 미국산 체리 11,900원
  
                          시즌 햇 옥수수&완두콩 개봉박두!
                          햇 초당옥수수 1,990원 햇 순창 완두콩 3,990원
  
                          초여름 보양 수산물!
                          완도산 활전복(중/소, 각 마리, 냉장, 국산) 2,500/1,250원 태국산 생 주꾸미 1,680원 생 손질낙지 1,980원
  
                          콩국물 가루 5,990원
                          허니버터 땅콩/와사비맛 땅콩/군옥수수맛 땅콩/카라멜땅콩 앤 프레첼/바프 마늘빵 병아리콩/바프 구운양파 병아리콩 2봉 구매시 1봉당 각2,495원
                      </div>
                      `,
      },
      {
        href: LEAFLET_URL.a1,
        imgs: "01/05.jpg",
        title: "롯데마트 전단5면",
        texts: `
                      <div>
                          나들이 준비 간편 요리!
                          김밥&롤 닭강정세트 7,800원 직접 구운 에그타르트 10,800원 열무김치 1,500~1,800원 샌드위치 피크닉 세트 8,800원 큰치킨 9,800원 한판 다모아새우초밥 17,800원
  
                          나들이 갈때 꼭 챙겨야 할 주류 추천!
                          파울라너 아이스박스 패키지 31,200원 칭타오 트레블 쿨러백 패키지 20,800원 컬럼비아 밸리 리슬링/군트럼 드라이 리슬링 22,400/16,640원
                          행사 와인과 동시 구매시 요리하다 얇은피 고기/김치 왕만두 2종 20% ▶기간 : 5/26(목) ~ 6/15(수)
  
                          제주 GAP 하우스 감귤 12,900원 스위티오/감숙왕 바나나 4,490원 유러피안 샐러드(2팩/국산) 2,990원 미국산 냉동 블루베리 9,900원 알래스카 황태채 17,900원 양반 참숯구이김 8,980원 동물복지 수비드 닭가슴살 7종 2개 구매시 L.POINT10% 각2,232원 3개 구매시 L.POINT20% 각1,984원 절단 코다리 6,800원 노르웨이 고등어자반 6,980원 온명란 기획 11,800원 원양산 손질오징어 9,900원 양념 민물장어 10,800원
                      </div>
                      `,
      },
      {
        href: LEAFLET_URL.a1,
        imgs: ["01/06.jpg"],
        title: "롯데마트 전단6면",
        texts: `
                      <div>
                          캠핑 먹거리 추천!
                          씨제이 스팸/스팸 25%라이트 13,480/13,780원 씨제이 햇반 작은햇반/햇반/큰햇반 4,100/5,400/7,400원 멜로 빅머쉬멜로우 980원
  
                          캠핑 필수템엔 꼬치다!
                          하코야 닭모둠 꼬치구이/모노키친 통닭다리살 꼬치 9,980/7,980원 고래사 꼬치어묵/새우봉꼬치/사각&물떡 5,450/7,850/8,900원 사조대림 빨간 오뎅꼬치 4,980원
  
                          풀무원 동치미 물냉면/평양 비빔+평양 물냉면 기획 각7,980원 진주햄 천하장사 더 건강하닭 7,290원 종가집 시원톡톡 유산균 배추김치 19,800원 오뚜기 화덕 Style 피자 2종 각8,980원 풀무원 얇은피 꽉찬속 고기만두/김치만두 각8,480원 아이배냇 이유식/유아간식 41종 1,500~11,900원 얼려먹는 야쿠르트 각2,400원 풀무원 다논 아이러브 요거트 3종 각2,980원 팔도&해찬들 차돌된장찌개라면/팔도×한성기업 크래미라면 각2,682원 동원×KBO 롯데 자이언츠 참치 外 16종 각10,980원 크라운 버터와플 2,980원 동서 포스트 현미 그래놀라 8,780원 씨스팡 관절팔팔 초록입홍합추출오일 29,900원 샤인머스캣/파인애플 엘라스틴 콜라겐 젤리스틱 각24,900원
                      </div>
                      `,
      },
      {
        href: LEAFLET_URL.a1,
        imgs: "01/07.jpg",
        title: "롯데마트 전단7면",
        texts: `
                      <div>
                          캠핑할 때 간편하게 한끼! 냉장/냉동 밀키트!
                          캠핑포차 김치어묵 우동전골 9,900원 바로 끓여먹는 부대찌개 9,900원 신림동식 철판 순대볶음 10,900원
  
                          초여름 준비! 시원하게 즐기는 요리하다 냉장면!
                          요리하다 메밀막국수 5,490원 요리하다 냉면기획 5,990원 요리하다 동치미 물냉면 3,590원 요리하다 함흥 비빔냉면 4,390원 
  
                          쟁여두면 마음이 든든! 일상용품 득템 찬스!
                          퍼실 파워젤/라벤더 용기형 각15,900원 리엔 물들임 샴푸/트리트먼트 34,000/19,000원 마이비데 물티슈 9,900원 크리넥스 피톤치드 화장지 19,950원
  
                          엘지생활건강 5만원 이상 구매시 1만원 롯데상품권 증정!
                          오가니스트 히말라야 핑크솔트 쿨링 바디워시/샴푸 6,450/7,450원 닥터그루트 제이몬스터즈 바디워시/샴푸 각22,000원 퐁퐁 리필 각4,450원 쏘피 유기농순면 무표백 속커버 각8,950원
  
                          일상 회복 준비는 롭스플러스에서! 색조/향수/네일/선케어/미용소품 카테고리 해당카드로 3만원 이상 10% 추가 할인!
                          CKONE/CK BE 각36,900원 데싱디바 매직프레스 네일/페디 13,300/9,700원 제니퍼로페즈 글로우바이제이로 24,900원 니베아 선세럼 13,900원 피지오겔/선스크린 28,000/19,900원
  
                          잘풀리는집 2만 5천원 이상 구매시 5천원 롯데상품권 증정!
                          잘풀리는집 롯데껌 미용티슈 3,300원 잘풀리는집 데코 3겹 화장지 16,900원
                      </div>
                      `,
      },
      {
        href: LEAFLET_URL.a1,
        imgs: "01/08.jpg",
        title: "롯데마트 전단8면",
        texts: `
                      <div>
                          깨끗한 물로 만든 소중한 한 끼 밥상!
                          룸바이홈 정수필터 주방헤드 세트 13,520/15,920원 룸바이홈 정수필터 9,520/10,320원 
  
                          피치스킨 균일가!
                          룸바이홈 피치스킨 차렵이불(싱글/퀸) 9,000/13,500원 로렌힐스 파워 서큘레이터 외 6종 31,430~39,920원
  
                          룸바이홈 울트라쿨 시리즈 15,900~29,900원 팜브라운 가드닝소품 6종 10,140~22,740원 룸바이홈 빈티지 슬림 이동식 선반(3단)/룸바이홈 빈티지 이동식 선반(3단/4단) 15,120/16,720/20,720원 불스원 레인OK 에탄올 3in1 코팅워셔 6,890원 룸바이홈 아로마 향기타월 각13,230원 룸바이홈 미니 아이스박스/캐리어 아이스박스 20,930/48,930원 룸바이홈 마블 주방매트(대)/파크론 블루다이아 다용도매트 13,930/27,930원 크린랩×하이퍼맥스 건전지 4,730~7,490원
  
                          여름 캠핑 필수 아이템!
                          허밋 캠핑 비치 파라솔 19,900원 파세코 탁상용 선풍기 7,900원 파세코 폴딩 서큘레이터 99,000원 파세코 폴딩 서큘레이터 149,000원
  
                          미리 준비하는 워터 스포츠!
                          스네프 아동용 조끼형 넥튜브(M) 18,900원 버팔로 파르벤 구명조끼(M/XL) 각24,900원 월드컵 코넛 아쿠아슈즈 각12,900원 마모트 성인 래쉬가드/스윔팬츠/레깅스 각28,900원 
  
                          쥬라기월드 영화 개봉 기념!
                          쥬라기월드2 티라노 패밀리 27,900원 쥬라기월드 다이노 이스케이프 어택 티렉스 43,400원 쥬라기월드 파이터다이노 피규어 外 16종 19,800~198,000원
  
                          아동 리플 트렁크/보나핏 남성 릴렉스핏 드로즈 4,130/6,230원 주니어 란제리 균일가(1입) 5,000/3,000원 지사 선캡/란체티 천연햇 각10,000원 번처벌룬 3팩 7,900원 여름 홈웨어 균일가 5,900/7,900/9,900원 울트라쿨 양말 균일가 1,000/3,000/5,000원 나이키 로말레오4 역도화(크로스핏) 242,100원 독블리 패드 11,000원
                      </div>
                      `,
      },
    ],
  },
  L402: {
    title: "스마트전단지-전점402",
    type: "HYPER",
    imgPath: "static/202205_4/",
    contents: [
      {
        href: LEAFLET_URL.a1,
        imgs: "01/01.jpg",
        title: "롯데마트 전단1면",
        texts: `
                      <h1>·전단적용기간 : 2022. 5. 26(목) ~ 2022. 5. 31(화)</h1>
                      <div>
                          기다렸던 일상의 재발견 3탄
                          맛있는 한끼 밥상
  
                          노을 메론(통/국산) 9,990원 설향 메론(1.5kg/박스/국산) 11,900원 브랜드 돼지 삼겹/목심 숯불용 각3,480원 초이스엘 국내산 대패 삼겹/목심 20,640/16,640원 물가안정 제주갈치 대/특대 4,900/9,100원
                      </div>
                      `,
      },
      {
        href: LEAFLET_URL.a1,
        imgs: "01/02.jpg",
        title: "롯데마트 전단2면",
        texts: `
                      <div>
                          우리가족 건강한 한 끼 밥상
  
                          부추 1,890원 현미/찰현미/혼합 8곡 각9,490원 상생 대파 2,490원 물가안정 참조기 1,800원 물가안정 국물용 멸치 8,900원 정부비축 울릉도 마른오징어 16,900원 통영 멍게 6,980원 한우 세절 국거리 기획팩 20,900원
  
                          농림축산식품부와 함께하는 농할할인 20%
                          갓 따온 그대로 사과 7,992원 대추방울 토마토 6,960원 다다기 오이 2,392원 양배추 2,952원 햇마늘 14,320원 파프리카 792원 양파 3,992원 산지직송 영암감자 5,592원 더담은 임금님표 이천쌀 30,320원 동물복지 닭볶음탕/백숙 각7,990원
                      </div>
                      `,
      },
      {
        href: LEAFLET_URL.a1,
        imgs: "01/03.jpg",
        title: "롯데마트 전단3면",
        texts: `
                      <div>
                          밀키트로 채우는 따뜻한 한 끼 밥상!
  
                          사천 마라샹궈 11,600원 요리하다×농가맛집 소고기버섯전골 16,720원 해물버섯 누룽지탕 12,720원 요리하다 우렁강된장 11,120원 요리하다 된장시래기 전골 12,720원 
  
                          청정원 호밍스 2만 5천원 이상 5천원 롯데상품권 증정!
                          호밍스 청송식 닭불고기, 언양식 바싹불고기/춘천식 닭갈비, 부산식 곱창전골/서울식 소불고기 전골, 낙곱새전골, 소고기버섯만두전골 각6,735/9,750/12,000원
  
                          오뚜기 케찹 外 10종 2,280~3,690원 르네 홀그레인 머스타드/마스터푸드 민트젤리 소스 3,890/4,990원 백설 천일염 허브맛솔트 마늘 外 3종 2,280~2,680원 신영 양꼬치 시즈닝 外 9종 1,790~14,990원
  
                          트라몬티나 커트러리 전품목 2,450~10,430원 룸바이홈 미드센츄리 식기/수저 4,560~16,900원 바이올라 와인글라스 14,000원 바인즈 단스탠드 外 2종 23,900~33,900원 룸바이홈 방수 스트라이프 식탁보 24,900원 이연복 클래딘 시그니처 프라이팬 29,520 ~35,920 원 룸바이홈 골드 프라이팬 시리즈 8,450~12,450 원
                      </div>
                      `,
      },
      {
        href: LEAFLET_URL.a1,
        imgs: "01/04.jpg",
        title: "롯데마트 전단4면",
        texts: `
                      <div>
                          먹.잘.알들의 PICK! 맛있는 캠핑 ITEM!
                          특별하게 즐기는 미국산 특수부위 4종! 스킨팩 토시살/꽃갈비살/본갈비살/립아이캡 L.POINT 5천원 할인 초이스 부채살 2,280원 초이스 꽃갈비살 7,560원 수제 모둠소시지 10,000원 불맛한판 직화 모듬곱창/불곱창 6,480/5,480원 간편하게 즐기는 냉동 스테이크 3종 L.POINT30%
  
                          초여름 더위를 꽉 잡는 몸보신용 한우 곰탕류 최대 L.POINT50%
                          한우 사골/잡뼈 8,250/5,500원 지리산 사골/고기 곰탕 L.POINT30%
  
                          스무가지 비타민과 미네랄! 달콤하게 시작하는 즐거운 건강습관!
                          제스프리 그린/골드 키위 6,990/9,990원 미국산 체리 11,900원
  
                          시즌 햇 옥수수&완두콩 개봉박두!
                          햇 초당옥수수 1,990원 햇 순창 완두콩 3,990원
  
                          초여름 보양 수산물!
                          완도산 활전복(중/소, 각 마리, 냉장, 국산) 2,500/1,250원 태국산 생 주꾸미 1,680원 생 손질낙지 1,980원
  
                          콩국물 가루 5,990원
                          허니버터 땅콩/와사비맛 땅콩/군옥수수맛 땅콩/카라멜땅콩 앤 프레첼/바프 마늘빵 병아리콩/바프 구운양파 병아리콩 2봉 구매시 1봉당 각2,495원
                      </div>
                      `,
      },
      {
        href: LEAFLET_URL.a1,
        imgs: "01/05.jpg",
        title: "롯데마트 전단5면",
        texts: `
                      <div>
                          나들이 준비 간편 요리!
                          김밥&롤 닭강정세트 7,800원 직접 구운 에그타르트 10,800원 열무김치 1,500~1,800원 샌드위치 피크닉 세트 8,800원 큰치킨 9,800원 한판 다모아새우초밥 17,800원
  
                          나들이 갈때 꼭 챙겨야 할 주류 추천!
                          파울라너 아이스박스 패키지 31,200원 칭타오 트레블 쿨러백 패키지 20,800원 컬럼비아 밸리 리슬링/군트럼 드라이 리슬링 22,400/16,640원
                          행사 와인과 동시 구매시 요리하다 얇은피 고기/김치 왕만두 2종 20% ▶기간 : 5/26(목) ~ 6/15(수)
  
                          제주 GAP 하우스 감귤 12,900원 스위티오/감숙왕 바나나 4,490원 유러피안 샐러드(2팩/국산) 2,990원 미국산 냉동 블루베리 9,900원 알래스카 황태채 17,900원 양반 참숯구이김 8,980원 동물복지 수비드 닭가슴살 7종 2개 구매시 L.POINT10% 각2,232원 3개 구매시 L.POINT20% 각1,984원 절단 코다리 6,800원 노르웨이 고등어자반 6,980원 온명란 기획 11,800원 원양산 손질오징어 9,900원 양념 민물장어 10,800원
                      </div>
                      `,
      },
      {
        href: LEAFLET_URL.a1,
        imgs: ["01/06.jpg"],
        title: "롯데마트 전단6면",
        texts: `
                      <div>
                          캠핑 먹거리 추천!
                          씨제이 스팸/스팸 25%라이트 13,480/13,780원 씨제이 햇반 작은햇반/햇반/큰햇반 4,100/5,400/7,400원 멜로 빅머쉬멜로우 980원
  
                          캠핑 필수템엔 꼬치다!
                          하코야 닭모둠 꼬치구이/모노키친 통닭다리살 꼬치 9,980/7,980원 고래사 꼬치어묵/새우봉꼬치/사각&물떡 5,450/7,850/8,900원 사조대림 빨간 오뎅꼬치 4,980원
  
                          풀무원 동치미 물냉면/평양 비빔+평양 물냉면 기획 각7,980원 진주햄 천하장사 더 건강하닭 7,290원 종가집 시원톡톡 유산균 배추김치 19,800원 오뚜기 화덕 Style 피자 2종 각8,980원 풀무원 얇은피 꽉찬속 고기만두/김치만두 각8,480원 아이배냇 이유식/유아간식 41종 1,500~11,900원 얼려먹는 야쿠르트 각2,400원 풀무원 다논 아이러브 요거트 3종 각2,980원 팔도&해찬들 차돌된장찌개라면/팔도×한성기업 크래미라면 각2,682원 동원×KBO 롯데 자이언츠 참치 外 16종 각10,980원 크라운 버터와플 2,980원 동서 포스트 현미 그래놀라 8,780원 씨스팡 관절팔팔 초록입홍합추출오일 29,900원 샤인머스캣/파인애플 엘라스틴 콜라겐 젤리스틱 각24,900원
                      </div>
                      `,
      },
      {
        href: LEAFLET_URL.a1,
        imgs: "01/07.jpg",
        title: "롯데마트 전단7면",
        texts: `
                      <div>
                          캠핑할 때 간편하게 한끼! 냉장/냉동 밀키트!
                          캠핑포차 김치어묵 우동전골 9,900원 바로 끓여먹는 부대찌개 9,900원 신림동식 철판 순대볶음 10,900원
  
                          초여름 준비! 시원하게 즐기는 요리하다 냉장면!
                          요리하다 메밀막국수 5,490원 요리하다 냉면기획 5,990원 요리하다 동치미 물냉면 3,590원 요리하다 함흥 비빔냉면 4,390원 
  
                          쟁여두면 마음이 든든! 일상용품 득템 찬스!
                          퍼실 파워젤/라벤더 용기형 각15,900원 리엔 물들임 샴푸/트리트먼트 34,000/19,000원 마이비데 물티슈 9,900원 크리넥스 피톤치드 화장지 19,950원
  
                          엘지생활건강 5만원 이상 구매시 1만원 롯데상품권 증정!
                          오가니스트 히말라야 핑크솔트 쿨링 바디워시/샴푸 6,450/7,450원 닥터그루트 제이몬스터즈 바디워시/샴푸 각22,000원 퐁퐁 리필 각4,450원 쏘피 유기농순면 무표백 속커버 각8,950원
  
                          일상 회복 준비는 롭스플러스에서! 색조/향수/네일/선케어/미용소품 카테고리 해당카드로 3만원 이상 10% 추가 할인!
                          CKONE/CK BE 각36,900원 데싱디바 매직프레스 네일/페디 13,300/9,700원 제니퍼로페즈 글로우바이제이로 24,900원 니베아 선세럼 13,900원 피지오겔/선스크린 28,000/19,900원
  
                          잘풀리는집 2만 5천원 이상 구매시 5천원 롯데상품권 증정!
                          잘풀리는집 롯데껌 미용티슈 3,300원 잘풀리는집 데코 3겹 화장지 16,900원
                      </div>
                      `,
      },
      {
        href: LEAFLET_URL.a1,
        imgs: "01/08.jpg",
        title: "롯데마트 전단8면",
        texts: `
                      <div>
                          깨끗한 물로 만든 소중한 한 끼 밥상!
                          룸바이홈 정수필터 주방헤드 세트 13,520/15,920원 룸바이홈 정수필터 9,520/10,320원 
  
                          피치스킨 균일가!
                          룸바이홈 피치스킨 차렵이불(싱글/퀸) 9,000/13,500원 로렌힐스 파워 서큘레이터 외 6종 31,430~39,920원
  
                          룸바이홈 울트라쿨 시리즈 15,900~29,900원 팜브라운 가드닝소품 6종 10,140~22,740원 룸바이홈 빈티지 슬림 이동식 선반(3단)/룸바이홈 빈티지 이동식 선반(3단/4단) 15,120/16,720/20,720원 불스원 레인OK 에탄올 3in1 코팅워셔 6,890원 룸바이홈 아로마 향기타월 각13,230원 룸바이홈 미니 아이스박스/캐리어 아이스박스 20,930/48,930원 룸바이홈 마블 주방매트(대)/파크론 블루다이아 다용도매트 13,930/27,930원 크린랩×하이퍼맥스 건전지 4,730~7,490원
  
                          여름 캠핑 필수 아이템!
                          허밋 캠핑 비치 파라솔 19,900원 파세코 탁상용 선풍기 7,900원 파세코 폴딩 서큘레이터 99,000원 파세코 폴딩 서큘레이터 149,000원
  
                          미리 준비하는 워터 스포츠!
                          스네프 아동용 조끼형 넥튜브(M) 18,900원 버팔로 파르벤 구명조끼(M/XL) 각24,900원 월드컵 코넛 아쿠아슈즈 각12,900원 마모트 성인 래쉬가드/스윔팬츠/레깅스 각28,900원 
  
                          쥬라기월드 영화 개봉 기념!
                          쥬라기월드2 티라노 패밀리 27,900원 쥬라기월드 다이노 이스케이프 어택 티렉스 43,400원 쥬라기월드 파이터다이노 피규어 外 16종 19,800~198,000원
  
                          아동 리플 트렁크/보나핏 남성 릴렉스핏 드로즈 4,130/6,230원 주니어 란제리 균일가(1입) 5,000/3,000원 지사 선캡/란체티 천연햇 각10,000원 번처벌룬 3팩 7,900원 여름 홈웨어 균일가 5,900/7,900/9,900원 울트라쿨 양말 균일가 1,000/3,000/5,000원 나이키 로말레오4 역도화(크로스핏) 242,100원 독블리 패드 11,000원
                      </div>
                      `,
      },
    ],
  },
  L403: {
    title: "스마트전단지-제타플렉스403",
    type: "HYPER",
    imgPath: "static/202205_4/",
    contents: [
      {
        href: LEAFLET_URL.a1,
        imgs: "01/jamsil_01.jpg",
        title: "제타플렉스 전단1면",
        texts: `
                          <h1>·전단적용기간 : 2022. 5. 26(목) ~ 2022. 5. 31(화) ·제타플렉스 잠실점</h1>
                          <div>
                              기다렸던 일상의 재발견 3탄
                              맛있는 한끼 밥상
  
                              노을 메론(통/국산) 9,990원 설향 메론(1.5kg/박스/국산) 11,900원 브랜드 돼지 삼겹/목심 숯불용 각3,480원 초이스엘 국내산 대패 삼겹/목심 20,640/16,640원 물가안정 제주갈치 대/특대 4,900/9,100원
                          </div>
                          `,
      },
      {
        href: LEAFLET_URL.a1,
        imgs: "01/jamsil_02.jpg",
        title: "제타플렉스 전단2면",
        texts: `
                          <div>
                              우리가족 건강한 한 끼 밥상
  
                              부추 1,890원 현미/찰현미/혼합 8곡 각9,490원 상생 대파 2,490원 물가안정 참조기 1,800원 물가안정 국물용 멸치 8,900원 정부비축 울릉도 마른오징어 16,900원 통영 멍게 6,980원 한우 세절 국거리 기획팩 20,900원
  
                              농림축산식품부와 함께하는 농할할인 20%
                              갓 따온 그대로 사과 7,992원 대추방울 토마토 6,960원 다다기 오이 2,392원 양배추 2,952원 햇마늘 14,320원 파프리카 792원 양파 3,992원 산지직송 영암감자 5,592원 더담은 임금님표 이천쌀 30,320원 동물복지 닭볶음탕/백숙 각7,990원
                          </div>
                          `,
      },
      {
        href: LEAFLET_URL.a1,
        imgs: "01/jamsil_03.jpg",
        title: "제타플렉스 전단3면",
        texts: `
                          <div>
                              밀키트로 채우는 따뜻한 한 끼 밥상!
  
                              사천 마라샹궈 11,600원 요리하다×농가맛집 소고기버섯전골 16,720원 해물버섯 누룽지탕 12,720원 요리하다 우렁강된장 11,120원 요리하다 된장시래기 전골 12,720원 
  
                              청정원 호밍스 2만 5천원 이상 5천원 롯데상품권 증정!
                              호밍스 청송식 닭불고기, 언양식 바싹불고기/춘천식 닭갈비, 부산식 곱창전골/서울식 소불고기 전골, 낙곱새전골, 소고기버섯만두전골 각6,735/9,750/12,000원
  
                              오뚜기 케찹 外 10종 2,280~3,690원 르네 홀그레인 머스타드/마스터푸드 민트젤리 소스 3,890/4,990원 백설 천일염 허브맛솔트 마늘 外 3종 2,280~2,680원 신영 양꼬치 시즈닝 外 9종 1,790~14,990원
  
                              트라몬티나 커트러리 전품목 2,450~10,430원 룸바이홈 미드센츄리 식기/수저 4,560~16,900원 바이올라 와인글라스 14,000원 바인즈 단스탠드 外 2종 23,900~33,900원 룸바이홈 방수 스트라이프 식탁보 24,900원 이연복 클래딘 시그니처 프라이팬 29,520 ~35,920 원 룸바이홈 골드 프라이팬 시리즈 8,450~12,450 원
                          </div>
                          `,
      },
      {
        href: LEAFLET_URL.a1,
        imgs: "01/jamsil_04.jpg",
        title: "제타플렉스 전단4면",
        texts: `
                          <div>
                              먹.잘.알들의 PICK! 맛있는 캠핑 ITEM!
                              특별하게 즐기는 미국산 특수부위 4종! 스킨팩 토시살/꽃갈비살/본갈비살/립아이캡 L.POINT 5천원 할인 초이스 부채살 2,280원 초이스 꽃갈비살 7,560원 수제 모둠소시지 10,000원 불맛한판 직화 모듬곱창/불곱창 6,480/5,480원 간편하게 즐기는 냉동 스테이크 3종 L.POINT30%
  
                              초여름 더위를 꽉 잡는 몸보신용 한우 곰탕류 최대 L.POINT50%
                              한우 사골/잡뼈 8,250/5,500원 지리산 사골/고기 곰탕 L.POINT30%
  
                              스무가지 비타민과 미네랄! 달콤하게 시작하는 즐거운 건강습관!
                              제스프리 그린/골드 키위 6,990/9,990원 미국산 체리 11,900원
  
                              시즌 햇 옥수수&완두콩 개봉박두!
                              햇 초당옥수수 1,990원 햇 순창 완두콩 3,990원
  
                              초여름 보양 수산물!
                              완도산 활전복(중/소, 각 마리, 냉장, 국산) 2,500/1,250원 태국산 생 주꾸미 1,680원 생 손질낙지 1,980원
  
                              콩국물 가루 5,990원
                              허니버터 땅콩/와사비맛 땅콩/군옥수수맛 땅콩/카라멜땅콩 앤 프레첼/바프 마늘빵 병아리콩/바프 구운양파 병아리콩 2봉 구매시 1봉당 각2,495원
                          </div>
                          `,
      },
      {
        href: LEAFLET_URL.a1,
        imgs: "01/jamsil_05.jpg",
        title: "제타플렉스 전단5면",
        texts: `
                          <div>
                              나들이 준비 간편 요리!
                              김밥&롤 닭강정세트 7,800원 직접 구운 에그타르트 10,800원 열무김치 1,500~1,800원 샌드위치 피크닉 세트 8,800원 큰치킨 9,800원 한판 다모아새우초밥 17,800원
  
                              나들이 갈때 꼭 챙겨야 할 주류 추천!
                              파울라너 아이스박스 패키지 31,200원 칭타오 트레블 쿨러백 패키지 20,800원
  
                              제주 GAP 하우스 감귤 12,900원 스위티오/감숙왕 바나나 4,490원 유러피안 샐러드(2팩/국산) 2,990원 미국산 냉동 블루베리 9,900원 알래스카 황태채 17,900원 양반 참숯구이김 8,980원 동물복지 수비드 닭가슴살 7종 2개 구매시 L.POINT10% 각2,232원 3개 구매시 L.POINT20% 각1,984원 절단 코다리 6,800원 노르웨이 고등어자반 6,980원 온명란 기획 11,800원 원양산 손질오징어 9,900원 양념 민물장어 10,800원
                          </div>
                          `,
      },
      {
        href: LEAFLET_URL.a1,
        imgs: "01/jamsil_06.jpg",
        title: "제타플렉스 전단6면",
        texts: `
                          <div>
                              캠핑 먹거리 추천!
                              씨제이 스팸/스팸 25%라이트 13,480/13,780원 씨제이 햇반 작은햇반/햇반/큰햇반 4,100/5,400/7,400원 멜로 빅머쉬멜로우 980원
  
                              캠핑 필수템엔 꼬치다!
                              하코야 닭모둠 꼬치구이/모노키친 통닭다리살 꼬치 9,980/7,980원 고래사 꼬치어묵/새우봉꼬치/사각&물떡 5,450/7,850/8,900원 사조대림 빨간 오뎅꼬치 4,980원
  
                              풀무원 동치미 물냉면/평양 비빔+평양 물냉면 기획 각7,980원 진주햄 천하장사 더 건강하닭 7,290원 종가집 시원톡톡 유산균 배추김치 19,800원 오뚜기 화덕 Style 피자 2종 각8,980원 풀무원 얇은피 꽉찬속 고기만두/김치만두 각8,480원 아이배냇 이유식/유아간식 41종 1,500~11,900원 얼려먹는 야쿠르트 각2,400원 풀무원 다논 아이러브 요거트 3종 각2,980원 팔도&해찬들 차돌된장찌개라면/팔도×한성기업 크래미라면 각2,682원 동원×KBO 롯데 자이언츠 참치 外 16종 각10,980원 크라운 버터와플 2,980원 동서 포스트 현미 그래놀라 8,780원 씨스팡 관절팔팔 초록입홍합추출오일 29,900원 샤인머스캣/파인애플 엘라스틴 콜라겐 젤리스틱 각24,900원
                          </div>
                          `,
      },
      {
        href: LEAFLET_URL.a1,
        imgs: "01/jamsil_07.jpg",
        title: "제타플렉스 전단7면",
        texts: `
                          <div>
                              캠핑할 때 간편하게 한끼! 냉장/냉동 밀키트!
                              캠핑포차 김치어묵 우동전골 9,900원 바로 끓여먹는 부대찌개 9,900원 신림동식 철판 순대볶음 10,900원
  
                              초여름 준비! 시원하게 즐기는 요리하다 냉장면!
                              요리하다 메밀막국수 5,490원 요리하다 냉면기획 5,990원 요리하다 동치미 물냉면 3,590원 요리하다 함흥 비빔냉면 4,390원 
  
                              쟁여두면 마음이 든든! 일상용품 득템 찬스!
                              퍼실 파워젤/라벤더 용기형 각15,900원 리엔 물들임 샴푸/트리트먼트 34,000/19,000원 마이비데 물티슈 9,900원 크리넥스 피톤치드 화장지 19,950원
  
                              엘지생활건강 5만원 이상 구매시 1만원 롯데상품권 증정!
                              오가니스트 히말라야 핑크솔트 쿨링 바디워시/샴푸 6,450/7,450원 닥터그루트 제이몬스터즈 바디워시/샴푸 각22,000원 퐁퐁 리필 각4,450원 쏘피 유기농순면 무표백 속커버 각8,950원
  
                              일상 회복 준비는 롭스플러스에서! 색조/향수/네일/선케어/미용소품 카테고리 해당카드로 3만원 이상 10% 추가 할인!
                              CKONE/CK BE 각36,900원 데싱디바 매직프레스 네일/페디 13,300/9,700원 제니퍼로페즈 글로우바이제이로 24,900원 니베아 선세럼 13,900원 피지오겔/선스크린 28,000/19,900원
  
                              잘풀리는집 2만 5천원 이상 구매시 5천원 롯데상품권 증정!
                              잘풀리는집 롯데껌 미용티슈 3,300원 잘풀리는집 데코 3겹 화장지 16,900원
                          </div>
                          `,
      },
      {
        href: LEAFLET_URL.a1,
        imgs: "01/jamsil_08.jpg",
        title: "제타플렉스 전단8면",
        texts: `
                          <div>
                              깨끗한 물로 만든 소중한 한 끼 밥상!
                              룸바이홈 정수필터 주방헤드 세트 13,520/15,920원 룸바이홈 정수필터 9,520/10,320원 
  
                              피치스킨 균일가!
                              룸바이홈 피치스킨 차렵이불(싱글/퀸) 9,000/13,500원 로렌힐스 파워 서큘레이터 외 6종 31,430~39,920원
  
                              룸바이홈 울트라쿨 시리즈 15,900~29,900원 팜브라운 가드닝소품 6종 10,140~22,740원 룸바이홈 빈티지 슬림 이동식 선반(3단)/룸바이홈 빈티지 이동식 선반(3단/4단) 15,120/16,720/20,720원 불스원 레인OK 에탄올 3in1 코팅워셔 6,890원 룸바이홈 아로마 향기타월 각13,230원 크린랩 다크그레이 고무장갑(대 2켤레) 3,040원 룸바이홈 마블 주방매트(대)/파크론 블루다이아 다용도매트 13,930/27,930원 크린랩×하이퍼맥스 건전지 4,730~7,490원
  
                              데카트론
                              "SPORT FOR THE MANY" 더 많은 사람들이 즐겁고 유익하게 누리는 스포츠
                              아동용 부력 조끼 각19,000원 이지브레스 스노쿨링 마스크 각29,900원 아동용 비치타올 각25,000원
  
                              쥬라기월드 영화 개봉 기념!
                              쥬라기월드2 티라노 패밀리 27,900원 쥬라기월드 다이노 이스케이프 어택 티렉스 43,400원 쥬라기월드 파이터다이노 피규어 外 16종 19,800~198,000원
  
                              아동 리플 트렁크/보나핏 남성 릴렉스핏 드로즈 4,130/6,230원 주니어 란제리 균일가(1입) 5,000/3,000원 번처벌룬 3팩 7,900원 보나핏 반팔 파자마 상하세트 19,900원 울트라쿨 양말 균일가 1,000/3,000/5,000원 독블리 패드 11,000원
                          </div>
                          `,
      },
    ],
  },
  L404: {
    title: "스마트전단지-제타플렉스404",
    type: "HYPER",
    imgPath: "static/202205_4/",
    contents: [
      {
        href: LEAFLET_URL.a1,
        imgs: "01/jamsil_01.jpg",
        title: "제타플렉스 전단1면",
        texts: `
                          <h1>·전단적용기간 : 2022. 5. 26(목) ~ 2022. 5. 31(화) ·제타플렉스 잠실점</h1>
                          <div>
                              기다렸던 일상의 재발견 3탄
                              맛있는 한끼 밥상
  
                              노을 메론(통/국산) 9,990원 설향 메론(1.5kg/박스/국산) 11,900원 브랜드 돼지 삼겹/목심 숯불용 각3,480원 초이스엘 국내산 대패 삼겹/목심 20,640/16,640원 물가안정 제주갈치 대/특대 4,900/9,100원
                          </div>
                          `,
      },
      {
        href: LEAFLET_URL.a1,
        imgs: "01/jamsil_02.jpg",
        title: "제타플렉스 전단2면",
        texts: `
                          <div>
                              우리가족 건강한 한 끼 밥상
  
                              부추 1,890원 현미/찰현미/혼합 8곡 각9,490원 상생 대파 2,490원 물가안정 참조기 1,800원 물가안정 국물용 멸치 8,900원 정부비축 울릉도 마른오징어 16,900원 통영 멍게 6,980원 한우 세절 국거리 기획팩 20,900원
  
                              농림축산식품부와 함께하는 농할할인 20%
                              갓 따온 그대로 사과 7,992원 대추방울 토마토 6,960원 다다기 오이 2,392원 양배추 2,952원 햇마늘 14,320원 파프리카 792원 양파 3,992원 산지직송 영암감자 5,592원 더담은 임금님표 이천쌀 30,320원 동물복지 닭볶음탕/백숙 각7,990원
                          </div>
                          `,
      },
      {
        href: LEAFLET_URL.a1,
        imgs: "01/jamsil_03.jpg",
        title: "제타플렉스 전단3면",
        texts: `
                          <div>
                              밀키트로 채우는 따뜻한 한 끼 밥상!
  
                              사천 마라샹궈 11,600원 요리하다×농가맛집 소고기버섯전골 16,720원 해물버섯 누룽지탕 12,720원 요리하다 우렁강된장 11,120원 요리하다 된장시래기 전골 12,720원 
  
                              청정원 호밍스 2만 5천원 이상 5천원 롯데상품권 증정!
                              호밍스 청송식 닭불고기, 언양식 바싹불고기/춘천식 닭갈비, 부산식 곱창전골/서울식 소불고기 전골, 낙곱새전골, 소고기버섯만두전골 각6,735/9,750/12,000원
  
                              오뚜기 케찹 外 10종 2,280~3,690원 르네 홀그레인 머스타드/마스터푸드 민트젤리 소스 3,890/4,990원 백설 천일염 허브맛솔트 마늘 外 3종 2,280~2,680원 신영 양꼬치 시즈닝 外 9종 1,790~14,990원
  
                              트라몬티나 커트러리 전품목 2,450~10,430원 룸바이홈 미드센츄리 식기/수저 4,560~16,900원 바이올라 와인글라스 14,000원 바인즈 단스탠드 外 2종 23,900~33,900원 룸바이홈 방수 스트라이프 식탁보 24,900원 이연복 클래딘 시그니처 프라이팬 29,520 ~35,920 원 룸바이홈 골드 프라이팬 시리즈 8,450~12,450 원
                          </div>
                          `,
      },
      {
        href: LEAFLET_URL.a1,
        imgs: "01/jamsil_04.jpg",
        title: "제타플렉스 전단4면",
        texts: `
                          <div>
                              먹.잘.알들의 PICK! 맛있는 캠핑 ITEM!
                              특별하게 즐기는 미국산 특수부위 4종! 스킨팩 토시살/꽃갈비살/본갈비살/립아이캡 L.POINT 5천원 할인 초이스 부채살 2,280원 초이스 꽃갈비살 7,560원 수제 모둠소시지 10,000원 불맛한판 직화 모듬곱창/불곱창 6,480/5,480원 간편하게 즐기는 냉동 스테이크 3종 L.POINT30%
  
                              초여름 더위를 꽉 잡는 몸보신용 한우 곰탕류 최대 L.POINT50%
                              한우 사골/잡뼈 8,250/5,500원 지리산 사골/고기 곰탕 L.POINT30%
  
                              스무가지 비타민과 미네랄! 달콤하게 시작하는 즐거운 건강습관!
                              제스프리 그린/골드 키위 6,990/9,990원 미국산 체리 11,900원
  
                              시즌 햇 옥수수&완두콩 개봉박두!
                              햇 초당옥수수 1,990원 햇 순창 완두콩 3,990원
  
                              초여름 보양 수산물!
                              완도산 활전복(중/소, 각 마리, 냉장, 국산) 2,500/1,250원 태국산 생 주꾸미 1,680원 생 손질낙지 1,980원
  
                              콩국물 가루 5,990원
                              허니버터 땅콩/와사비맛 땅콩/군옥수수맛 땅콩/카라멜땅콩 앤 프레첼/바프 마늘빵 병아리콩/바프 구운양파 병아리콩 2봉 구매시 1봉당 각2,495원
                          </div>
                          `,
      },
      {
        href: LEAFLET_URL.a1,
        imgs: "01/jamsil_05.jpg",
        title: "제타플렉스 전단5면",
        texts: `
                          <div>
                              나들이 준비 간편 요리!
                              김밥&롤 닭강정세트 7,800원 직접 구운 에그타르트 10,800원 열무김치 1,500~1,800원 샌드위치 피크닉 세트 8,800원 큰치킨 9,800원 한판 다모아새우초밥 17,800원
  
                              나들이 갈때 꼭 챙겨야 할 주류 추천!
                              파울라너 아이스박스 패키지 31,200원 칭타오 트레블 쿨러백 패키지 20,800원
  
                              제주 GAP 하우스 감귤 12,900원 스위티오/감숙왕 바나나 4,490원 유러피안 샐러드(2팩/국산) 2,990원 미국산 냉동 블루베리 9,900원 알래스카 황태채 17,900원 양반 참숯구이김 8,980원 동물복지 수비드 닭가슴살 7종 2개 구매시 L.POINT10% 각2,232원 3개 구매시 L.POINT20% 각1,984원 절단 코다리 6,800원 노르웨이 고등어자반 6,980원 온명란 기획 11,800원 원양산 손질오징어 9,900원 양념 민물장어 10,800원
                          </div>
                          `,
      },
      {
        href: LEAFLET_URL.a1,
        imgs: "01/jamsil_06.jpg",
        title: "제타플렉스 전단6면",
        texts: `
                          <div>
                              캠핑 먹거리 추천!
                              씨제이 스팸/스팸 25%라이트 13,480/13,780원 씨제이 햇반 작은햇반/햇반/큰햇반 4,100/5,400/7,400원 멜로 빅머쉬멜로우 980원
  
                              캠핑 필수템엔 꼬치다!
                              하코야 닭모둠 꼬치구이/모노키친 통닭다리살 꼬치 9,980/7,980원 고래사 꼬치어묵/새우봉꼬치/사각&물떡 5,450/7,850/8,900원 사조대림 빨간 오뎅꼬치 4,980원
  
                              풀무원 동치미 물냉면/평양 비빔+평양 물냉면 기획 각7,980원 진주햄 천하장사 더 건강하닭 7,290원 종가집 시원톡톡 유산균 배추김치 19,800원 오뚜기 화덕 Style 피자 2종 각8,980원 풀무원 얇은피 꽉찬속 고기만두/김치만두 각8,480원 아이배냇 이유식/유아간식 41종 1,500~11,900원 얼려먹는 야쿠르트 각2,400원 풀무원 다논 아이러브 요거트 3종 각2,980원 팔도&해찬들 차돌된장찌개라면/팔도×한성기업 크래미라면 각2,682원 동원×KBO 롯데 자이언츠 참치 外 16종 각10,980원 크라운 버터와플 2,980원 동서 포스트 현미 그래놀라 8,780원 씨스팡 관절팔팔 초록입홍합추출오일 29,900원 샤인머스캣/파인애플 엘라스틴 콜라겐 젤리스틱 각24,900원
                          </div>
                          `,
      },
      {
        href: LEAFLET_URL.a1,
        imgs: "01/jamsil_07.jpg",
        title: "제타플렉스 전단7면",
        texts: `
                          <div>
                              캠핑할 때 간편하게 한끼! 냉장/냉동 밀키트!
                              캠핑포차 김치어묵 우동전골 9,900원 바로 끓여먹는 부대찌개 9,900원 신림동식 철판 순대볶음 10,900원
  
                              초여름 준비! 시원하게 즐기는 요리하다 냉장면!
                              요리하다 메밀막국수 5,490원 요리하다 냉면기획 5,990원 요리하다 동치미 물냉면 3,590원 요리하다 함흥 비빔냉면 4,390원 
  
                              쟁여두면 마음이 든든! 일상용품 득템 찬스!
                              퍼실 파워젤/라벤더 용기형 각15,900원 리엔 물들임 샴푸/트리트먼트 34,000/19,000원 마이비데 물티슈 9,900원 크리넥스 피톤치드 화장지 19,950원
  
                              엘지생활건강 5만원 이상 구매시 1만원 롯데상품권 증정!
                              오가니스트 히말라야 핑크솔트 쿨링 바디워시/샴푸 6,450/7,450원 닥터그루트 제이몬스터즈 바디워시/샴푸 각22,000원 퐁퐁 리필 각4,450원 쏘피 유기농순면 무표백 속커버 각8,950원
  
                              일상 회복 준비는 롭스플러스에서! 색조/향수/네일/선케어/미용소품 카테고리 해당카드로 3만원 이상 10% 추가 할인!
                              CKONE/CK BE 각36,900원 데싱디바 매직프레스 네일/페디 13,300/9,700원 제니퍼로페즈 글로우바이제이로 24,900원 니베아 선세럼 13,900원 피지오겔/선스크린 28,000/19,900원
  
                              잘풀리는집 2만 5천원 이상 구매시 5천원 롯데상품권 증정!
                              잘풀리는집 롯데껌 미용티슈 3,300원 잘풀리는집 데코 3겹 화장지 16,900원
                          </div>
                          `,
      },
      {
        href: LEAFLET_URL.a1,
        imgs: "01/jamsil_08.jpg",
        title: "제타플렉스 전단8면",
        texts: `
                          <div>
                              깨끗한 물로 만든 소중한 한 끼 밥상!
                              룸바이홈 정수필터 주방헤드 세트 13,520/15,920원 룸바이홈 정수필터 9,520/10,320원 
  
                              피치스킨 균일가!
                              룸바이홈 피치스킨 차렵이불(싱글/퀸) 9,000/13,500원 로렌힐스 파워 서큘레이터 외 6종 31,430~39,920원
  
                              룸바이홈 울트라쿨 시리즈 15,900~29,900원 팜브라운 가드닝소품 6종 10,140~22,740원 룸바이홈 빈티지 슬림 이동식 선반(3단)/룸바이홈 빈티지 이동식 선반(3단/4단) 15,120/16,720/20,720원 불스원 레인OK 에탄올 3in1 코팅워셔 6,890원 룸바이홈 아로마 향기타월 각13,230원 크린랩 다크그레이 고무장갑(대 2켤레) 3,040원 룸바이홈 마블 주방매트(대)/파크론 블루다이아 다용도매트 13,930/27,930원 크린랩×하이퍼맥스 건전지 4,730~7,490원
  
                              데카트론
                              "SPORT FOR THE MANY" 더 많은 사람들이 즐겁고 유익하게 누리는 스포츠
                              아동용 부력 조끼 각19,000원 이지브레스 스노쿨링 마스크 각29,900원 아동용 비치타올 각25,000원
  
                              쥬라기월드 영화 개봉 기념!
                              쥬라기월드2 티라노 패밀리 27,900원 쥬라기월드 다이노 이스케이프 어택 티렉스 43,400원 쥬라기월드 파이터다이노 피규어 外 16종 19,800~198,000원
  
                              아동 리플 트렁크/보나핏 남성 릴렉스핏 드로즈 4,130/6,230원 주니어 란제리 균일가(1입) 5,000/3,000원 번처벌룬 3팩 7,900원 보나핏 반팔 파자마 상하세트 19,900원 울트라쿨 양말 균일가 1,000/3,000/5,000원 독블리 패드 11,000원
                          </div>
                          `,
      },
    ],
  },
  L704: {
    title: "스마트전단지-빅마켓704",
    type: "MAXX",
    imgPath: "static/202205_4/",
    contents: [
      {
        href: LEAFLET_URL.a1,
        imgs: "07/01.jpg",
        title: "빅마켓 전단1면",
        texts: `
              <h1>·전단적용기간 : 2022. 5. 26(목) ~ 2022. 5. 31(화) ㆍ롯데빅마켓 : 금천, 영등포점</h1>
              <div>
                  놀라운 가격 물가 안정 VIC
              </div>
          `,
      },
      {
        href: LEAFLET_URL.a1,
        imgs: "07/02.jpg",
        title: "빅마켓 전단2면",
        texts: `
              <div>
                  VIC Savings
  
                  VIC Surprise
                  껍질이 바삭한 구운 캐슈넛 6,980원 직화구이 육포 5,980원 맥심 카누 마일드/다크 미니 23,980원 칠성사이다/칠성사이다제로 에코패키지 7,480/6,980원 락앤락 테이블 물병 5,880원
              </div>
          `,
      },
    ],
  },
  L744: {
    title: "스마트전단지-빅마켓744",
    type: "MAXX",
    imgPath: "static/202205_4/",
    contents: [
      {
        href: LEAFLET_URL.a1,
        imgs: "07/01.jpg",
        title: "빅마켓 전단1면",
        texts: `
              <h1>·전단적용기간 : 2022. 5. 26(목) ~ 2022. 5. 31(화) ㆍ롯데빅마켓 : 금천, 영등포점</h1>
              <div>
                  놀라운 가격 물가 안정 VIC
              </div>
          `,
      },
      {
        href: LEAFLET_URL.a1,
        imgs: "07/02.jpg",
        title: "빅마켓 전단2면",
        texts: `
              <div>
                  VIC Savings
  
                  VIC Surprise
                  껍질이 바삭한 구운 캐슈넛 6,980원 직화구이 육포 5,980원 맥심 카누 마일드/다크 미니 23,980원 칠성사이다/칠성사이다제로 에코패키지 7,480/6,980원 락앤락 테이블 물병 5,880원
              </div>
          `,
      },
    ],
  },
  L921: {
    title: "스마트전단지-전점921",
    type: "HYPER",
    imgPath: "/resource/image/static/202205_4/",
    contents: [
      {
        href: LEAFLET_URL.a1,
        imgs: "09/01.jpg",
        title: "롯데마트 전단1면",
        texts: `
              <h1>·전단적용기간 : 2022. 5. 26(목) ~ 소진시까지 ·해당점 : 구미, 대덕, 부평, 사상, 송파, 월드컵, 의왕, 춘천점</h1>
              <div>
                  최대 70% 가격인하 라스트 찬스
  
                  조리도구
  
                  주방잡화
  
                  테이블웨어
  
                  인테리어
  
                  라이프스타일
              </div>
              `,
      },
      {
        href: LEAFLET_URL.a1,
        imgs: "09/02.jpg",
        title: "롯데마트 전단2면",
        texts: `
              <div>
                  일상용품
  
                  HOME
  
                  언더웨어
  
                  양말
  
                  패션잡화
              </div>
              `,
      },
    ],
  },
};

export default data;
