import React from "react";
import { Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import "./styles/reset.css";
import "./styles/swiper.min.css";

import List from "./containers/List";

import LM from "./containers/LM";

function App() {
  const addGoogleAnalyticsScript = () => {
    return {
      __html: `
        window.gtag('config', 'UA-68431978-1');
      `,
    };
  };
  return (
    <>
      <Helmet>
        {/* Global site tag (gtag.js) - Google Analytics
            페이지뷰 - window.gtag('config','GA_TRACKING_ID',{page_title:'',page_location:location.pathname,page_path:'[롯데마트 GO] 홈'});
            이벤트 - window.gtag('event', '액션', {event_category: '카테고리',event_label: '라벨'});
        */}
        <script src="https://www.googletagmanager.com/gtag/js?id=UA-68431978-1"></script>
        <script dangerouslySetInnerHTML={addGoogleAnalyticsScript()} />
      </Helmet>
      <Routes>
        <Route exact path="/list" element={<List />} />

        <Route exact path="/lm/401" element={<LM />} />
        <Route exact path="/lm/402" element={<LM />} />
        <Route exact path="/lm/403" element={<LM />} />
        <Route exact path="/lm/404" element={<LM />} />
      </Routes>
    </>
  );
}

export default App;
