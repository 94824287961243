const data = {
  L601: {
    title: "스마트전단지-매거진601",
    type: "",
    imgPath: "static/images/601/",
    contents: [
      {
        href: "",
        imgs: "01.jpg",
        title: "생활공감",
        texts: `                             
                  <h1>vol.09 롯데마트가 전하는 생활 속 공감 이야기 생활공감 행복의 온도</h1>
                  `,
      },
      {
        href: "",
        imgs: "02.jpg",
        title: "생활공감",
        texts: `                                
                  <h1>일상의 행복을 찾아가는 여정 행복의 온도</h1>
                  `,
      },
      {
        href: "",
        imgs: "03.jpg",
        title: "생활공감",
        texts: `                                
                  <h1>목차</h1>
                  `,
      },
      {
        href: "",
        imgs: "04.jpg",
        title: "생활공감",
        texts: `                                
                  <h1>행복의 온도</h1>
                  `,
      },
      {
        href: "",
        imgs: "05.jpg",
        title: "생활공감",
        texts: `                                
              <h1>내입이 가장 행복한 온도를 찾아서</h1>                                              
              `,
      },
      {
        href: "",
        imgs: "06.jpg",
        title: "생활공감",
        texts: `                                
              <h1>뜨겁거나 혹은 차갑거나</h1>                                               
              `,
      },
      {
        href: "",
        imgs: "07.jpg",
        title: "생활공감",
        texts: `                                
              <h1>재료마다 요리마다 맛있는 온도는 따로있다</h1>
              `,
      },
      {
        href: "",
        imgs: "08.jpg",
        title: "생활공감",
        texts: `                                
              <h1>재료마다 음식마다 사람들이 맛있다고 생각하는 온도는 따로 있다</h1>
              `,
      },
      {
        href: "",
        imgs: "09.jpg",
        title: "생활공감",
        texts: `                                
              <h1>온도가 빚어내는 맛의 비밀</h1>
              `,
      },
      {
        href: "",
        imgs: "10.jpg",
        title: "생활공감",
        texts: `                                
              <h1>롯데마트 FIC센터 강레오셰프 인터뷰</h1>
              `,
      },
    ],
  },
  L901: {
    title: "스마트전단지-룸바이홈901",
    type: "",
    imgPath: "static/images/901/",
    contents: [
      {
        href: "",
        imgs: "01.jpg",
        title: "룸바이홈",
        texts: `                             
                  <h1>기 능 주 의 디 자 인 룸바이홈</h1>
                  `,
      },
      {
        href: "",
        imgs: "02.jpg",
        title: "룸바이홈",
        texts: `                                
                <h1>Less, But Better</h1>
                `,
      },
      {
        href: "",
        imgs: "03.jpg",
        title: "룸바이홈",
        texts: `                                
              <h1>Functionism [펑셔니즘]</h1>
              `,
      },
      {
        href: "",
        imgs: "04.jpg",
        title: "룸바이홈",
        texts: `                                
                  <h1>EARTHY, KINFOLK</h1>
                  `,
      },
      {
        href: "",
        imgs: "05.jpg",
        title: "룸바이홈",
        texts: `                                
                <h1>URBAN, COZY, PURE</h1>                                              
                `,
      },
      {
        href: "",
        imgs: "06.jpg",
        title: "룸바이홈",
        texts: `                                
              <h1>우드스틸 첼시</h1>                                               
              `,
      },
      {
        href: "",
        imgs: "07.jpg",
        title: "룸바이홈",
        texts: `                                
            <h1>우드스틸 첼시 선반, 내추럴 갤러리 액자, 우드스틸 첼시 바 테이블/스툴, 우드스틸 첼시 선반행거</h1>
            `,
      },
      {
        href: "",
        imgs: "08.jpg",
        title: "룸바이홈",
        texts: `                                
            <h1>Earthy look [나무 흙 돌 바람]</h1>
            `,
      },
      {
        href: "",
        imgs: "09.jpg",
        title: "룸바이홈",
        texts: `                                
            <h1>헤리티지 대용량 모던디퓨저, 샤인 차콜그레이 메탈 벽시계, 센티드가든 디퓨저, 헤리티지 대용량 모던디퓨저, 분갈이가 필요없는 도자기 화분, 플랜트 박스</h1>
            `,
      },
      {
        href: "",
        imgs: "10.jpg",
        title: "룸바이홈",
        texts: `                                
            <h1>룸바이홈 쇼핑 캐리어. 트렌디 아이템으로 진화된 실용성 높은 카트 장바구니 라인.</h1>
            `,
      },
      {
        href: "",
        imgs: "11.jpg",
        title: "룸바이홈",
        texts: `                                
            <h1>원터치 폴링웨건 그린, 우드테이블카트, 미니 폴딩박스, 롤링 캐리어, 접이식 핸드캐리어, 특대형 타포린백</h1>
            `,
      },
      {
        href: "",
        imgs: "12.jpg",
        title: "룸바이홈",
        texts: `                                
            <h1>룸바이홈 홈패브릭. 복고를 즐기는 뉴트로 트렌드를 반영한 깅엄 민트+옐로우 체크 라인.</h1>
            `,
      },
      {
        href: "",
        imgs: "13.jpg",
        title: "룸바이홈",
        texts: `                                
            <h1>우드스틸 소호 책상, 우드스틸 소호 책상세트, 우드스틸 소호 선반, 내추럴 갤러리 액자, 가구+수납 모듈, 라탄의 텍스쳐를 구현</h1>
            `,
      },
      {
        href: "",
        imgs: "14.jpg",
        title: "룸바이홈",
        texts: `                                
            <h1>룸바이홈 캠프닉 봄 여름 나들이 필수품 룸바이홈 피크닉&캠핑 아이템.</h1>
            `,
      },
      {
        href: "",
        imgs: "15.jpg",
        title: "룸바이홈",
        texts: `                                
            <h1>캠핑비치타월 스트라이프/에스닉, 스텐식기, 휴대가 용이한 이불</h1>
            `,
      },
    ],
  },

  L908: {
    title: "스마트전단지-룸바이홈랩908",
    type: "",
    imgPath: "static/images/908/",
    contents: [
      {
        href: "",
        imgs: "lab_01.jpg",
        title: "룸바이홈",
        texts: `
                <h1>ROOM X HOME Lab 룸바이홈랩 12.9목 GRAND OPEN</h1>
              `,
      },
      {
        href: "",
        imgs: "lab_02.jpg",
        title: "룸바이홈",
        texts: `                                
              <h1>Kichen Bedding Gardening</h1>
              `,
      },
      {
        href: "",
        imgs: "lab_03.jpg",
        title: "룸바이홈",
        texts: `                                
                <h1>Furniture Bath Fabric Deco&Diffuser</h1>
                `,
      },
      {
        href: "",
        imgs: "lab_04.jpg",
        title: "룸바이홈",
        texts: `                                
                <h1>ROOM X HOME Lab 룸바이홈랩 TOP12 추천상품</h1>
                `,
      },
    ],
  },
};

export default data;
