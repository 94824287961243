import { LEAFLET_URL } from "../constants";

const data = {
  L301: {
    title: "스마트전단지-전점301",
    type: "HYPER",
    imgPath: "static/images/202205_3/",
    contents: [
      {
        href: LEAFLET_URL.a1,
        imgs: "01/01.jpg",
        title: "롯데마트 전단1면",
        texts: `
                  <h1>·전단적용기간 : 2022. 5. 19(목) ~ 2022. 5. 25(수)</h1>
                  <div>
                      기다렸던 일상의 재발견 2탄
                      5월, 캠핑하기 좋은 날
          
                      캠핑하면 글로벌 구이지! 소고기&양고기&삼겹살!
                      ▶기간 : 5/19(목) ~ 5/25(수)
                      양고기 숄더렉/목심 각4,750원 양고기 삼겹살 8,900원 타즈매니아 척아이롤/부채살 2,324/2,548원 캐나다 돼지 삼겹/목심 1,780/1,580원 스페인산 냉동 삼겹/목심 12,460/8,960원 
          
                      달콤하고 시원한 여름 과일의 맛!
                      황금당도 성주참외 9,990원 당도선별 성주참외 9,990원 WM12 수박/당도선별 수박(5~9kg/통/국산) 수박 2,000원 할인!
                  </div>
                  `,
      },
      {
        href: LEAFLET_URL.a1,
        imgs: "01/02.jpg",
        title: "롯데마트 전단2면",
        texts: `
                  <div>
                      숙성으로 완성한 부드러움의 정점!
                      ▶기간 : 5/19(목) ~ 5/25(수)
                      숙성한우 등심(2등급) 6,860원 한우 갈비살 L.POINT30% 숙성한우 안심/채끝(2등급) 11,060/8,540원
          
                      롯데마트는 토마토에 진심!
                      스위텔토마토 6,990원 찰토마토 10,900원
          
                      GAP 농산물 기획전
                      GAP 세종 삼광쌀 23,920원 GAP 트리벨리 파프리카 792원 GAP 친환경 새송이버섯 2,792원 GAP 충남 오감양송이 4,392원 GAP 매운고추(150g/봉/국산)/GAP 오이맛고추 각1,992원 GAP 가지 1,512원
          
                      제주 황토밭 하우스 감귤 12,900원 미국산 오렌지 9,900원 미국산 레몬 6,990원 제스프리 골드키위 1,190원 햇 무/햇 양파 1,690/4,990원 미국산 체리/GAP 국산 블루베리 12,900/7,990원 갓 따온 그대로 사과 7,990원 경북 청송 사과 7,990원 하얀 반숙란 설란/사육번호 2번 구운란 각2,990원 팥/서리태 각9,900원
                  </div>
                  `,
      },
      {
        href: LEAFLET_URL.a1,
        imgs: "01/03.jpg",
        title: "롯데마트 전단3면",
        texts: `
                  <div>
                      해양수산부와 함께하는 물가안정 수산물!
                      물가안정 제주 은갈치 4,900/9,100원 물가안정 참조기 1,800원 물가안정 국물용멸치 9,900원 
          
                      나들이 준비 간편 요리!
                      아이들 간식으로 딱 좋은 오리지널 핫도그 3입 6,900원 청포도 리코타 치즈 샐러드/쉬림프 콥 샐러드 각9,800원 양장피 13,800원 경양식 함박스테이크 9,800원 버라이어티 초밥 19,800원
          
                      5월 롯데마트 랍스타와 함께 나들이 하새우!
                      흰다리새우/블랙타이거 13,800/12,600원 껍질벗긴새우 20,900원 껍질벗긴새우 9,900원 자숙랍스타 12,800원
          
                      베트남 건과일에 빠지다! 폴링인브이 시리즈!
                      폴링인브이 시리즈 9,990원
          
                      해외종자 로열티 ZERO!
                      대천 골드 1호 식탁김/재래김 5,490/6,990원
          
                      한 봉 가득 우삼겹 9,900원 프라임 채끝 18,800원 완도 활 전복 각10,000원 국산 데친 문어 3,480원 울릉도 마른 오징어 17,900원 양념 소 LA꽃갈비 구이 29,900원 미국산 냉동 LA식 갈비 4,480원 훈제오리 슬라이스 9,800/10,900원 굴비 10,800원 광어연어새조개피조개 모둠회 25,800원
                  </div>
                  `,
      },
      {
        href: LEAFLET_URL.a1,
        imgs: "01/04.jpg",
        title: "롯데마트 전단4면",
        texts: `
                  <div>
                      캠핑&나들이 먹거리!
                      씨제이 스팸/스팸 25%라이트 13,480/13,780원 씨제이 햇반 작은햇반/햇반/큰햇반(각 3입) 4,100/5,400/7,400원 삼진 우리가족 한끼 어묵탕 3,380원 고래사 꼬치어묵/새우봉꼬치/사각&물떡 5,450/7,850/8,900원 모노키친 통닭다리살 꼬치/하코야 닭모둠 꼬치 7,980/9,980원 한성 콤비부어스트 모듬소시지/씨제이 더 건강한 그릴후랑크 6,480/6,980원 알찬 명이나물절임 外상온 반찬 10종 3,980/4,980원 사조대림 주부초밥짱/간편김밥세트 2,980/5,980원 한성 크래미 4,980원 토레타 3,400원 롯데 꼬깔콘치토스 기획 2,980원 크라운 참크래커 2,680원 동원참치 스프레드 고소마요 8,980원
          
                      LG생활건강 5만원 이상 구매시 1만원 롯데상품권 증정!
                      퐁퐁 리필 각4,450원 쏘피 유기농순면 무표백 속커버(중형 36입/대형 32입/슈퍼롱 20입) 각8,950원
          
                      일상용품 득템찬스!
                      크리넥스 피톤치드+마이비데 2만 5천원 이상 구매시 5천원 롯데상품권 증정!
                      퍼실 파워젤/라벤더 용기형 15,900원 크리넥스 피톤치드 화장지 19,950원 마이비데 물티슈 9,900원
          
                      새로운 발견 롯데마트 오리지널
                      요리하다 간장계란/불닭 닭가슴살곤약볶음밥/해빗 콜리플라워 볶음밥 각2,590원 요리하다 메밀막국수 5,490원 요리하다 냉면기획 5,990원 요리하다 동치미 물냉면 3,590원 요리하다 함흥 비빔냉면 4,390원
                  </div>
                  `,
      },
    ],
  },
  L302: {
    title: "스마트전단지-전점302",
    type: "HYPER",
    imgPath: "static/images/202205_3/",
    contents: [
      {
        href: LEAFLET_URL.a1,
        imgs: "01/01.jpg",
        title: "롯데마트 전단1면",
        texts: `
              <h1>·전단적용기간 : 2022. 5. 19(목) ~ 2022. 5. 25(수)</h1>
              <div>
                  기다렸던 일상의 재발견 2탄
                  5월, 캠핑하기 좋은 날
      
                  캠핑하면 글로벌 구이지! 소고기&양고기&삼겹살!
                  ▶기간 : 5/19(목) ~ 5/25(수)
                  양고기 숄더렉/목심 각4,750원 양고기 삼겹살 8,900원 타즈매니아 척아이롤/부채살 2,324/2,548원 캐나다 돼지 삼겹/목심 1,780/1,580원 스페인산 냉동 삼겹/목심 12,460/8,960원 
      
                  달콤하고 시원한 여름 과일의 맛!
                  황금당도 성주참외 9,990원 당도선별 성주참외 9,990원 WM12 수박/당도선별 수박(5~9kg/통/국산) 수박 2,000원 할인!
              </div>
              `,
      },
      {
        href: LEAFLET_URL.a1,
        imgs: "01/02.jpg",
        title: "롯데마트 전단2면",
        texts: `
              <div>
                  숙성으로 완성한 부드러움의 정점!
                  ▶기간 : 5/19(목) ~ 5/25(수)
                  숙성한우 등심(2등급) 6,860원 한우 갈비살 L.POINT30% 숙성한우 안심/채끝(2등급) 11,060/8,540원
      
                  롯데마트는 토마토에 진심!
                  스위텔토마토 6,990원 찰토마토 10,900원
      
                  GAP 농산물 기획전
                  GAP 세종 삼광쌀 23,920원 GAP 트리벨리 파프리카 792원 GAP 친환경 새송이버섯 2,792원 GAP 충남 오감양송이 4,392원 GAP 매운고추(150g/봉/국산)/GAP 오이맛고추 각1,992원 GAP 가지 1,512원
      
                  제주 황토밭 하우스 감귤 12,900원 미국산 오렌지 9,900원 미국산 레몬 6,990원 제스프리 골드키위 1,190원 햇 무/햇 양파 1,690/4,990원 미국산 체리/GAP 국산 블루베리 12,900/7,990원 갓 따온 그대로 사과 7,990원 경북 청송 사과 7,990원 하얀 반숙란 설란/사육번호 2번 구운란 각2,990원 팥/서리태 각9,900원
              </div>
              `,
      },
      {
        href: LEAFLET_URL.a1,
        imgs: "01/03.jpg",
        title: "롯데마트 전단3면",
        texts: `
              <div>
                  해양수산부와 함께하는 물가안정 수산물!
                  물가안정 제주 은갈치 4,900/9,100원 물가안정 참조기 1,800원 물가안정 국물용멸치 9,900원 
      
                  나들이 준비 간편 요리!
                  아이들 간식으로 딱 좋은 오리지널 핫도그 3입 6,900원 청포도 리코타 치즈 샐러드/쉬림프 콥 샐러드 각9,800원 양장피 13,800원 경양식 함박스테이크 9,800원 버라이어티 초밥 19,800원
      
                  5월 롯데마트 랍스타와 함께 나들이 하새우!
                  흰다리새우/블랙타이거 13,800/12,600원 껍질벗긴새우 20,900원 껍질벗긴새우 9,900원 자숙랍스타 12,800원
      
                  베트남 건과일에 빠지다! 폴링인브이 시리즈!
                  폴링인브이 시리즈 9,990원
      
                  해외종자 로열티 ZERO!
                  대천 골드 1호 식탁김/재래김 5,490/6,990원
      
                  한 봉 가득 우삼겹 9,900원 프라임 채끝 18,800원 완도 활 전복 각10,000원 국산 데친 문어 3,480원 울릉도 마른 오징어 17,900원 양념 소 LA꽃갈비 구이 29,900원 미국산 냉동 LA식 갈비 4,480원 훈제오리 슬라이스 9,800/10,900원 굴비 10,800원 광어연어새조개피조개 모둠회 25,800원
              </div>
              `,
      },
      {
        href: LEAFLET_URL.a1,
        imgs: "01/04.jpg",
        title: "롯데마트 전단4면",
        texts: `
              <div>
                  캠핑&나들이 먹거리!
                  씨제이 스팸/스팸 25%라이트 13,480/13,780원 씨제이 햇반 작은햇반/햇반/큰햇반(각 3입) 4,100/5,400/7,400원 삼진 우리가족 한끼 어묵탕 3,380원 고래사 꼬치어묵/새우봉꼬치/사각&물떡 5,450/7,850/8,900원 모노키친 통닭다리살 꼬치/하코야 닭모둠 꼬치 7,980/9,980원 한성 콤비부어스트 모듬소시지/씨제이 더 건강한 그릴후랑크 6,480/6,980원 알찬 명이나물절임 外상온 반찬 10종 3,980/4,980원 사조대림 주부초밥짱/간편김밥세트 2,980/5,980원 한성 크래미 4,980원 토레타 3,400원 롯데 꼬깔콘치토스 기획 2,980원 크라운 참크래커 2,680원 동원참치 스프레드 고소마요 8,980원
      
                  LG생활건강 5만원 이상 구매시 1만원 롯데상품권 증정!
                  퐁퐁 리필 각4,450원 쏘피 유기농순면 무표백 속커버(중형 36입/대형 32입/슈퍼롱 20입) 각8,950원
      
                  일상용품 득템찬스!
                  크리넥스 피톤치드+마이비데 2만 5천원 이상 구매시 5천원 롯데상품권 증정!
                  퍼실 파워젤/라벤더 용기형 15,900원 크리넥스 피톤치드 화장지 19,950원 마이비데 물티슈 9,900원
      
                  새로운 발견 롯데마트 오리지널
                  요리하다 간장계란/불닭 닭가슴살곤약볶음밥/해빗 콜리플라워 볶음밥 각2,590원 요리하다 메밀막국수 5,490원 요리하다 냉면기획 5,990원 요리하다 동치미 물냉면 3,590원 요리하다 함흥 비빔냉면 4,390원
              </div>
              `,
      },
    ],
  },
  L303: {
    title: "스마트전단지-제타플렉스303",
    type: "HYPER",
    imgPath: "static/images/202205_3/",
    contents: [
      {
        href: LEAFLET_URL.a1,
        imgs: "01/jamsil_01.jpg",
        title: "롯데마트 전단1면",
        texts: `
            <h1>·전단적용기간 : 2022. 5. 19(목) ~ 2022. 5. 25(수) ·제타플렉스 잠실점</h1>
            <div>
                기다렸던 일상의 재발견 2탄
                5월, 캠핑하기 좋은 날
  
                캠핑하면 글로벌 구이지! 소고기&양고기&삼겹살!
                ▶기간 : 5/19(목) ~ 5/25(수)
                양고기 숄더렉/목심 각4,750원 양고기 삼겹살 8,900원 타즈매니아 척아이롤/부채살 2,324/2,548원 캐나다 돼지 삼겹/목심 1,780/1,580원 스페인산 냉동 삼겹/목심 12,460/8,960원 
  
                달콤하고 시원한 여름 과일의 맛!
                황금당도 성주참외 9,990원 당도선별 성주참외 9,990원 WM12 수박/당도선별 수박(5~9kg/통/국산) 수박 2,000원 할인!
            </div>
            `,
      },
      {
        href: LEAFLET_URL.a1,
        imgs: "01/jamsil_02.jpg",
        title: "롯데마트 전단2면",
        texts: `
            <div>
              저지방 암소와 숙성으로 완성한 부드러움의 정점!
              ▶기간 : 5/19(목) ~ 5/25(수)
              숙성한우 등심(2등급) 6,860원 한우 갈비살 L.POINT30% 숙성한우 안심/채끝(2등급) 11,060/8,540원
  
              롯데마트는 토마토에 진심!
              스위텔토마토 6,990원 찰토마토 10,900원
  
              GAP 농산물 기획전
              GAP 세종 삼광쌀 23,920원 GAP 트리벨리 파프리카 792원 GAP 친환경 새송이버섯 2,792원 GAP 충남 오감양송이 4,392원 GAP 매운고추(150g/봉/국산)/GAP 오이맛고추 각1,992원 GAP 가지 1,512원
  
              제주 황토밭 하우스 감귤 12,900원 미국산 오렌지 9,900원 미국산 레몬 6,990원 제스프리 골드키위 1,190원 햇 무/햇 양파 1,690/4,990원 미국산 체리/GAP 국산 블루베리 12,900/7,990원 갓 따온 그대로 사과 7,990원 경북 청송 사과 7,990원 하얀 반숙란 설란/사육번호 2번 구운란 각2,990원 팥/서리태 각9,900원
            </div>
            `,
      },
      {
        href: LEAFLET_URL.a1,
        imgs: "01/jamsil_03.jpg",
        title: "롯데마트 전단3면",
        texts: `
            <div>
                해양수산부와 함께하는 물가안정 수산물!
                물가안정 제주 은갈치 4,900/9,100원 물가안정 참조기 1,800원 물가안정 국물용멸치 9,900원 
  
                나들이 준비 간편 요리!
                양장피 13,800원 경양식 함박스테이크 9,800원 허니견과치즈볼/통돈까스샌드/햄송이샌드 각3,900원 청포도 리코타 치즈 샐러드/쉬림프 콥 샐러드 각9,800원 아이들 간식으로 딱 좋은 오리지널 핫도그 3입 6,900원 버라이어티 초밥 19,800원
  
                명장 초청 시연회
                ▶대한민국 요리명장 남대현
                ▶진행일시 : 5/21(토) 17~18시
  
                5월 롯데마트 랍스타와 함께 나들이 하새우!
                흰다리새우/블랙타이거 13,800/12,600원 껍질벗긴새우 20,900원 껍질벗긴새우 9,900원 자숙랍스타 12,800원
  
                베트남 건과일에 빠지다! 폴링인브이 시리즈!
                폴링인브이 시리즈 9,990원
  
                해외종자 로열티 ZERO!
                대천 골드 1호 식탁김/재래김 5,490/6,990원
  
                한 봉 가득 우삼겹 9,900원 프라임 채끝 18,800원 완도 활 전복 각10,000원 국산 데친 문어 3,480원 울릉도 마른 오징어 17,900원 양념 소 LA꽃갈비 구이 29,900원 미국산 냉동 LA식 갈비 4,480원 훈제오리 슬라이스 9,800/10,900원 굴비 10,800원 광어연어새조개피조개 모둠회 25,800원
            </div>
            `,
      },
      {
        href: LEAFLET_URL.a1,
        imgs: "01/jamsil_04.jpg",
        title: "롯데마트 전단4면",
        texts: `
            <div>
                캠핑&나들이 먹거리!
                씨제이 스팸/스팸 25%라이트 13,480/13,780원 씨제이 햇반 작은햇반/햇반/큰햇반(각 3입) 4,100/5,400/7,400원 삼진 우리가족 한끼 어묵탕 3,380원 고래사 꼬치어묵/새우봉꼬치/사각&물떡 5,450/7,850/8,900원 모노키친 통닭다리살 꼬치/하코야 닭모둠 꼬치 7,980/9,980원 한성 콤비부어스트 모듬소시지/씨제이 더 건강한 그릴후랑크 6,480/6,980원 알찬 명이나물절임 外상온 반찬 10종 3,980/4,980원 사조대림 주부초밥짱/간편김밥세트 2,980/5,980원 한성 크래미 4,980원 토레타 3,400원 롯데 꼬깔콘치토스 기획 2,980원 크라운 참크래커 2,680원 동원참치 스프레드 고소마요 8,980원
  
                LG생활건강 5만원 이상 구매시 1만원 롯데상품권 증정!
                퐁퐁 리필 각4,450원 쏘피 유기농순면 무표백 속커버(중형 36입/대형 32입/슈퍼롱 20입) 각8,950원
  
                일상용품 득템찬스!
                크리넥스 피톤치드+마이비데 2만 5천원 이상 구매시 5천원 롯데상품권 증정!
                퍼실 파워젤/라벤더 용기형 15,900원 크리넥스 피톤치드 화장지 19,950원 마이비데 물티슈 9,900원
  
                새로운 발견 롯데마트 오리지널
                요리하다 간장계란/불닭 닭가슴살곤약볶음밥/해빗 콜리플라워 볶음밥 각2,590원 요리하다 메밀막국수 5,490원 요리하다 냉면기획 5,990원 요리하다 동치미 물냉면 3,590원 요리하다 함흥 비빔냉면 4,390원
            </div>
            `,
      },
    ],
  },
  L304: {
    title: "스마트전단지-제타플렉스304",
    type: "HYPER",
    imgPath: "static/images/202205_3/",
    contents: [
      {
        href: LEAFLET_URL.a1,
        imgs: "01/jamsil_01.jpg",
        title: "롯데마트 전단1면",
        texts: `
            <h1>·전단적용기간 : 2022. 5. 19(목) ~ 2022. 5. 25(수) ·제타플렉스 잠실점</h1>
            <div>
                기다렸던 일상의 재발견 2탄
                5월, 캠핑하기 좋은 날
  
                캠핑하면 글로벌 구이지! 소고기&양고기&삼겹살!
                ▶기간 : 5/19(목) ~ 5/25(수)
                양고기 숄더렉/목심 각4,750원 양고기 삼겹살 8,900원 타즈매니아 척아이롤/부채살 2,324/2,548원 캐나다 돼지 삼겹/목심 1,780/1,580원 스페인산 냉동 삼겹/목심 12,460/8,960원 
  
                달콤하고 시원한 여름 과일의 맛!
                황금당도 성주참외 9,990원 당도선별 성주참외 9,990원 WM12 수박/당도선별 수박(5~9kg/통/국산) 수박 2,000원 할인!
            </div>
            `,
      },
      {
        href: LEAFLET_URL.a1,
        imgs: "01/jamsil_02.jpg",
        title: "롯데마트 전단2면",
        texts: `
            <div>
              저지방 암소와 숙성으로 완성한 부드러움의 정점!
              ▶기간 : 5/19(목) ~ 5/25(수)
              숙성한우 등심(2등급) 6,860원 한우 갈비살 L.POINT30% 숙성한우 안심/채끝(2등급) 11,060/8,540원
  
              롯데마트는 토마토에 진심!
              스위텔토마토 6,990원 찰토마토 10,900원
  
              GAP 농산물 기획전
              GAP 세종 삼광쌀 23,920원 GAP 트리벨리 파프리카 792원 GAP 친환경 새송이버섯 2,792원 GAP 충남 오감양송이 4,392원 GAP 매운고추(150g/봉/국산)/GAP 오이맛고추 각1,992원 GAP 가지 1,512원
  
              제주 황토밭 하우스 감귤 12,900원 미국산 오렌지 9,900원 미국산 레몬 6,990원 제스프리 골드키위 1,190원 햇 무/햇 양파 1,690/4,990원 미국산 체리/GAP 국산 블루베리 12,900/7,990원 갓 따온 그대로 사과 7,990원 경북 청송 사과 7,990원 하얀 반숙란 설란/사육번호 2번 구운란 각2,990원 팥/서리태 각9,900원
            </div>
            `,
      },
      {
        href: LEAFLET_URL.a1,
        imgs: "01/jamsil_03.jpg",
        title: "롯데마트 전단3면",
        texts: `
            <div>
                해양수산부와 함께하는 물가안정 수산물!
                물가안정 제주 은갈치 4,900/9,100원 물가안정 참조기 1,800원 물가안정 국물용멸치 9,900원 
  
                나들이 준비 간편 요리!
                양장피 13,800원 경양식 함박스테이크 9,800원 허니견과치즈볼/통돈까스샌드/햄송이샌드 각3,900원 청포도 리코타 치즈 샐러드/쉬림프 콥 샐러드 각9,800원 아이들 간식으로 딱 좋은 오리지널 핫도그 3입 6,900원 버라이어티 초밥 19,800원
  
                명장 초청 시연회
                ▶대한민국 요리명장 남대현
                ▶진행일시 : 5/21(토) 17~18시
  
                5월 롯데마트 랍스타와 함께 나들이 하새우!
                흰다리새우/블랙타이거 13,800/12,600원 껍질벗긴새우 20,900원 껍질벗긴새우 9,900원 자숙랍스타 12,800원
  
                베트남 건과일에 빠지다! 폴링인브이 시리즈!
                폴링인브이 시리즈 9,990원
  
                해외종자 로열티 ZERO!
                대천 골드 1호 식탁김/재래김 5,490/6,990원
  
                한 봉 가득 우삼겹 9,900원 프라임 채끝 18,800원 완도 활 전복 각10,000원 국산 데친 문어 3,480원 울릉도 마른 오징어 17,900원 양념 소 LA꽃갈비 구이 29,900원 미국산 냉동 LA식 갈비 4,480원 훈제오리 슬라이스 9,800/10,900원 굴비 10,800원 광어연어새조개피조개 모둠회 25,800원
            </div>
            `,
      },
      {
        href: LEAFLET_URL.a1,
        imgs: "01/jamsil_04.jpg",
        title: "롯데마트 전단4면",
        texts: `
            <div>
                캠핑&나들이 먹거리!
                씨제이 스팸/스팸 25%라이트 13,480/13,780원 씨제이 햇반 작은햇반/햇반/큰햇반(각 3입) 4,100/5,400/7,400원 삼진 우리가족 한끼 어묵탕 3,380원 고래사 꼬치어묵/새우봉꼬치/사각&물떡 5,450/7,850/8,900원 모노키친 통닭다리살 꼬치/하코야 닭모둠 꼬치 7,980/9,980원 한성 콤비부어스트 모듬소시지/씨제이 더 건강한 그릴후랑크 6,480/6,980원 알찬 명이나물절임 外상온 반찬 10종 3,980/4,980원 사조대림 주부초밥짱/간편김밥세트 2,980/5,980원 한성 크래미 4,980원 토레타 3,400원 롯데 꼬깔콘치토스 기획 2,980원 크라운 참크래커 2,680원 동원참치 스프레드 고소마요 8,980원
  
                LG생활건강 5만원 이상 구매시 1만원 롯데상품권 증정!
                퐁퐁 리필 각4,450원 쏘피 유기농순면 무표백 속커버(중형 36입/대형 32입/슈퍼롱 20입) 각8,950원
  
                일상용품 득템찬스!
                크리넥스 피톤치드+마이비데 2만 5천원 이상 구매시 5천원 롯데상품권 증정!
                퍼실 파워젤/라벤더 용기형 15,900원 크리넥스 피톤치드 화장지 19,950원 마이비데 물티슈 9,900원
  
                새로운 발견 롯데마트 오리지널
                요리하다 간장계란/불닭 닭가슴살곤약볶음밥/해빗 콜리플라워 볶음밥 각2,590원 요리하다 메밀막국수 5,490원 요리하다 냉면기획 5,990원 요리하다 동치미 물냉면 3,590원 요리하다 함흥 비빔냉면 4,390원
            </div>
            `,
      },
    ],
  },
};

export default data;
