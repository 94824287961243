import React from "react";
import "../styles/list.css";

function List() {
  return (
    <div className="list">
      <h1>롯데마트 스마트전단</h1>
      <div id="contents">
        <h2>2022년 5월 4주차</h2>
        <h3>롯데마트GO</h3>
        <ul>
          <li>
            <a href="/leaflet?num=401">401 롯데마트</a>
          </li>
          <li>
            <a href="/leaflet?num=704">704 빅마켓</a>
          </li>
          <li>
            <a href="/leaflet?num=403">403 제타플렉스</a>
          </li>
          <li>
            <a href="/leaflet?num=921">921 집중처분8점</a>
          </li>
        </ul>
        <h3>LMS</h3>
        <ul>
          <li>
            <a href="/leaflet?num=402">402 롯데마트</a>
          </li>
          <li>
            <a href="/leaflet?num=404">404 제타플렉스</a>
          </li>
          <li>
            <a href="/leaflet?num=744">744 맥스</a>
          </li>
        </ul>
        <h2>2022년 5월 3주차</h2>
        <h3>롯데마트GO</h3>
        <ul>
          <li>
            <a href="/leaflet?num=301">301 롯데마트</a>
          </li>
          <li>
            <a href="/leaflet?num=303">303 제타플렉스</a>
          </li>
        </ul>
        <h3>LMS</h3>
        <ul>
          <li>
            <a href="/leaflet?num=302">302 롯데마트</a>
          </li>
          <li>
            <a href="/leaflet?num=304">304 제타플렉스</a>
          </li>
          <li>
            <a href="/leaflet?num=601">601 생활공감</a>
          </li>
          <li>
            <a href="/leaflet?num=901">901 룸바이홈</a>
          </li>
          <li>
            <a href="/leaflet?num=908">908 룸바이홈랩</a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default List;
